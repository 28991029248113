#blocklyDiv, 
#bfBlocklyDiv,
#brickPreviewDiv {
  height: 100%;
  width: 100%;
  /* position: relative; */
  /* position: absolute; */
  bottom: 0;
}

.blocklyMainBackground {
    stroke: none !important;
}
