.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #282c34;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter:hover {
    background-color: #302f38;;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

.button.gutter-horizontal {
    width: 10px;
    height: 70px;
    padding: 0px;
    border: 0px;
    color: lightgray;
    background-color: #585c54;
    cursor: default;
}

.button.gutter-horizontal:hover {
    color: beige;
    background-color: #3c3c3c;
}

.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    cursor: row-resize;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    height: 100%;
    background: #282c34;
}

#root {
    height: 100%;
    background: #282c34;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #282c34;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #424242;
    border-radius: 10px;
}

/* Corner */
::-webkit-scrollbar-corner {
    background: #282c34;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* blurred background modal for bootstrap v4 */
/* body.modal-open > :not(.modal) {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
} */